<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card :title="`Ubah user '${formData.username}'`" :loading="cardloading">
        <template slot="action">
          <c-button :to="{name:'users'}" variant="outline-primary">Kembali</c-button>
          <c-button v-if="!cardloading" :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-component component-name="b-form-input" label="Username" v-model="formData.username" :rules="{required: true}"></form-component>
          <form-component component-name="b-form-input" label="Nama Lengkap" v-model="formData.name" :rules="{required: true}"></form-component>
          <form-select-all label="Jabatan" v-model="formData.group" endpoint="v1/user_groups/select2" :rules="{required: true}"></form-select-all>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormComponent from '@/utils/components/FormComponent.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'

export default {
  components: { CCard, FormComponent, CForm, CButton, FormCheckbox, FormSelectAll },
  data(){
    const formData = {
      name:'',
      username:'',
      group:null,
      is_active: true
    }
    return {
      formData,
      isloading: false,
      cardloading: true
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      formData.id_group = vm.formData.group.value
      this.$http.put(`v1/users/update`,formData,{params:{id:vm.$route.params.id,code:formData.username}}).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'users'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    },
    getData(){
      const vm = this
      vm.cardloading = true
      vm.$http.get(`v1/users/show`,{params:{id:vm.$route.params.id}}).then(({data}) => {
        vm.formData = {
          username: data.username,
          name: data.name,
          group: {value:data.id_group, label:data.user_group.name},
          is_active: data.is_active ? true : false
        }
        vm.cardloading = false
      })
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style>

</style>